import { Image, Modal } from "antd";
import { useEffect, useState } from "react";
import vs from "@/assets/vs.webp";
import dot from "@/assets/dot.webp";
import close from "@/assets/btn-close.webp";
import dayjs from "dayjs";

function Popup({ title, schedule, isOpen, setIsOpen, isMobile }) {
  const formatTime = (value) => {
    const date = dayjs.unix(value);
    const formattedDate = date.format("DD/MM/YYYY HH:mm");
    return formattedDate;
  };

  return (
    <div className="relative">
      <Modal open={isOpen} className="" closeIcon={null}>
        <div
          className={`absolute -left-1 z-[99999] ${
            isMobile ? "-top-20" : "-top-40"
          }`}
        >
          <Image src={title} width="100%" preview={false} />
        </div>
        <table>
          {schedule?.map((item, index) =>
            isMobile ? (
              <tr key={index}>
                <td>{formatTime(item?.matchTime)}</td>
                <td>
                  <Image src={item?.homeLogo} width="50%" preview={false} />
                  <p className="border-[1px] rounded-full p-2 text-center">
                    {item?.homeName}
                  </p>
                </td>
                <td>
                  <Image src={vs} width="100%" preview={false} />
                </td>
                <td>
                  <Image src={item?.awayLogo} width="50%" preview={false} />
                  <p className="border-[1px] rounded-full p-2 text-center">
                    {item?.awayName}
                  </p>
                </td>
              </tr>
            ) : (
              <tr key={index}>
                <td>
                  <Image src={dot} width="30%" preview={false} />
                </td>
                <td>{formatTime(item?.matchTime)}</td>
                <td>
                  <p className="border-[1px] rounded-full p-2 text-center">
                    {item?.homeName}
                  </p>
                </td>
                <td>
                  <Image src={item?.homeLogo} width="50%" preview={false} />
                </td>
                <td>
                  <Image src={vs} width="100%" preview={false} />
                </td>
                <td>
                  <Image src={item?.awayLogo} width="50%" preview={false} />
                </td>
                <td>
                  <p className="border-[1px] rounded-full p-2 text-center">
                    {item?.awayName}
                  </p>
                </td>
              </tr>
            )
          )}
        </table>

        <div
          className={`absolute z-[999999] cursor-pointer ${
            isMobile ? "-top-24 -right-10" : " -top-28 -right-20"
          }`}
          onClick={() => setIsOpen(false)}
        >
          <Image
            src={close}
            width={`${isMobile ? "30%" : "40%"}`}
            preview={false}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Popup;
