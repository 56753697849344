import bgVideo from "@/assets/bg.mp4";
import logo from "@/assets/logo.webp";
import btnRegister from "@/assets/btn-register.webp";
import btnRegisterMb from "@/assets/btn-register-mb.webp";
import btnLogin from "@/assets/btn-login.webp";
import btnLoginMb from "@/assets/btn-login-mb.webp";
import decoVideo from "@/assets/deco-video.webp";
import video from "@/assets/video.mp4";
import item1 from "@/assets/item1.webp";
import item2 from "@/assets/item2.webp";
import item3 from "@/assets/item3.webp";
import item4 from "@/assets/item4.webp";
import item5 from "@/assets/item5.webp";
import item1Effect from "@/assets/item1Effect.webp";
import item2Effect from "@/assets/item2Effect.webp";
import item3Effect from "@/assets/item3Effect.webp";
import item4Effect from "@/assets/item4Effect.webp";
import item5Effect from "@/assets/item5Effect.webp";
import titlePremier from "@/assets/title-premier.webp";
import titleBundesliga from "@/assets/title-bundesliga.webp";
import titleSerieA from "@/assets/title-seriea.webp";
import titleLigue1 from "@/assets/title-ligue1.webp";
import titleLaliga from "@/assets/title-laliga.webp";
import pagingLeft from "@/assets/paging-left.webp";
import pagingRight from "@/assets/paging-right.webp";
import calendar from "@/assets/calendar-mb.webp";

import { Image } from "antd";
import Link from "antd/es/typography/Link";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import axios from "axios";
import Popup from "@/components/Popup";
import ModalPolicy from "@/components/ModalPolicy";

const listImage = [
  { url: item1, name: "premier" },
  { url: item2, name: "laliga" },
  { url: item3, name: "seriea" },
  { url: item4, name: "bundesliga" },
  { url: item5, name: "ligue1" },
];

function Home() {
  const [imageSrc, setImageSrc] = useState(item1);
  const [imageSrc2, setImageSrc2] = useState(item2);
  const [imageSrc3, setImageSrc3] = useState(item3);
  const [imageSrc4, setImageSrc4] = useState(item4);
  const [imageSrc5, setImageSrc5] = useState(item5);
  const [schedule, setSchedule] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const initialSlide = Math.floor((listImage.length + 1) / 2);

  const fetchSchedule = async (leagueId) => {
    try {
      const response = await axios.get(
        `https://api-ls.cdnokvip.com/api/get-schedule?leagueId=${leagueId}`
      );
      setSchedule(response?.data?.value?.datas?.data);
    } catch (error) {
      console.error("Error fetching the schedule:", error);
    }
  };

  const test = async () => {
    try {
      axios
        .post(
          "https://business-api.tiktok.com/open_api/v1.3/event/track/",
          {
            event_source: "web",
            event_source_id: "CQSS1SRC77UFGSJEH7Q0",
            data: [
              {
                event: "CompleteRegistration",
                event_time: "946684800000",
                page: {
                  url: "https://sieucupok9.com/",
                },
              },
            ],
          },
          {
            headers: {
              "Access-Token": "dea2992b1dba7ab8f4d6c4b646224f24ffde2660",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    test();
  }, []);

  const handleClick = (value) => {
    if (value === "premier") {
      fetchSchedule(1639);
      setTitle(titlePremier);
    } else if (value === "laliga") {
      fetchSchedule(1134);
      setTitle(titleLaliga);
    } else if (value === "seriea") {
      fetchSchedule(1437);
      setTitle(titleSerieA);
    } else if (value === "ligue1") {
      fetchSchedule(1112);
      setTitle(titleLigue1);
    } else if (value === "bundesliga") {
      fetchSchedule(188);
      setTitle(titleBundesliga);
    }
    setIsOpen(true);
  };

  return (
    <>
      <ModalPolicy />
      <div className="w-full relative">
        <video
          autoPlay
          muted
          loop
          className="h-screen object-cover w-full z-0 lg:h-auto"
        >
          <source src={bgVideo} type="video/mp4" />
        </video>
        {isMobile ? (
          <>
            {" "}
            <div className="w-full absolute top-0 z-10 flex items-center justify-center text-white ">
              <div className="w-[65%]">
                <Image src={logo} width="100%" preview={false} />
              </div>
            </div>
            <div className="w-full absolute top-[10%] z-10 flex items-center justify-center">
              <Link
                href="https://ok999.me/sport5"
                target="_blank"
                className="text-end !inline w-[45%]"
              >
                <Image
                  src={btnRegisterMb}
                  className=""
                  width="100%"
                  preview={false}
                />
              </Link>
              <Link
                href="https://ok999.me/sport5"
                target="_blank"
                className="inline w-[45%]"
              >
                <Image
                  src={btnLoginMb}
                  className=""
                  width="100%"
                  preview={false}
                />
              </Link>
            </div>
          </>
        ) : (
          <div className="w-full absolute top-0 z-10 flex items-center justify-center text-white ">
            <Link
              href="https://ok999.me/sport5"
              target="_blank"
              className="text-end !inline w-[13%]"
            >
              <Image
                src={btnRegister}
                className=""
                width="100%"
                preview={false}
              />
            </Link>
            <div className="w-[20%]">
              <Image src={logo} width="100%" preview={false} />
            </div>
            <Link
              href="https://ok999.me/sport5"
              target="_blank"
              className="inline w-[13%]"
            >
              <Image src={btnLogin} className="" width="100%" preview={false} />
            </Link>
          </div>
        )}

        <div className="w-full lg:w-[45%] absolute p-3 top-[17%] lg:top-[11%] left-[50%] -translate-x-[50%]">
          <div className="video-layout p-4 lg:px-7 lg:py-4 relative">
            <div className="absolute -top-2 -left-4 lg:-top-8 lg:-left-8 z-30 w-[10%]">
              <Image
                src={decoVideo}
                width="100%"
                className=""
                preview={false}
              />
            </div>
            <video
              controls
              loop
              autoPlay
              className="z-10 w-full rounded-3xl relative"
            >
              <source src={video} type="video/mp4" />
            </video>
            <div className="absolute -bottom-5 -right-3 lg:-bottom-8 lg:-right-12 z-20 w-[10%]">
              <Image
                src={decoVideo}
                width="100%"
                className=""
                preview={false}
              />
            </div>
          </div>
        </div>
        {isMobile ? (
          <div className="flex justify-center items-center">
            <div className="w-full absolute -bottom-5 mx-auto">
              <Swiper
                effect="coverflow"
                grabCursor={true}
                // onSlideChange={handleSlideChange}
                centeredSlides={true}
                loop={true}
                slidesPerView="auto"
                coverflowEffect={{
                  rotate: 0,
                  stretch: 18,
                  depth: 100,
                  modifier: 2.47,
                  slideShadows: false,
                }}
                initialSlide={initialSlide}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                  clickable: true,
                }}
                modules={[EffectCoverflow, Navigation]}
                className="swiper_container1"
              >
                {listImage?.map((item, index) => (
                  <SwiperSlide className="h-auto" key={index}>
                    <div
                      className="h-auto"
                      onClick={() => handleClick(item?.name)}
                    >
                      <Image src={item?.url} preview={false} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="slider-controller">
                <div className="swiper-button-prev slider-arrow left-[10px] top-1/2 -translate-y-1/2">
                  <Image src={pagingLeft} width="100%" preview={false} />
                </div>
                <div className="swiper-button-next slider-arrow absolute w-full h-full right-[10px] top-1/2 -translate-y-1/2">
                  <Image src={pagingRight} width="100%" preview={false} />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 w-full calendar-bg-mb">
              <Image
                src={calendar}
                preview={false}
                width="100%"
                className="absolute -bottom-36 z-20"
              />
              <div className="bg-[url('./assets/footer-mb.webp')] bg-cover bg-center h-full calendar-mb relative z-10"></div>
            </div>
          </div>
        ) : (
          <div className="relative h-full">
            <div className="absolute bottom-0 flex gap-0 justify-between overflow-y-hidden">
              <div
                className="relative cursor-pointer item1-layout"
                onMouseEnter={() => setImageSrc(item1Effect)}
                onMouseLeave={() => setImageSrc(item1)}
                onClick={() => handleClick("premier")}
              >
                <Image
                  src={imageSrc}
                  width="100%"
                  height="100%"
                  className="item1"
                  preview={false}
                />
              </div>
              <div
                className="relative cursor-pointer item1-layout"
                onMouseEnter={() => setImageSrc2(item2Effect)}
                onMouseLeave={() => setImageSrc2(item2)}
                onClick={() => handleClick("laliga")}
              >
                <Image
                  src={imageSrc2}
                  width="100%"
                  height="100%"
                  className="item1"
                  preview={false}
                />
              </div>
              <div
                className="relative cursor-pointer item1-layout"
                onMouseEnter={() => setImageSrc3(item3Effect)}
                onMouseLeave={() => setImageSrc3(item3)}
                onClick={() => handleClick("seriea")}
              >
                <Image
                  src={imageSrc3}
                  width="100%"
                  height="100%"
                  className="item1"
                  preview={false}
                />
              </div>
              <div
                className="relative cursor-pointer item1-layout"
                onMouseEnter={() => setImageSrc4(item4Effect)}
                onMouseLeave={() => setImageSrc4(item4)}
                onClick={() => handleClick("bundesliga")}
              >
                <Image
                  src={imageSrc4}
                  width="100%"
                  height="100%"
                  className="item1"
                  preview={false}
                />
              </div>
              <div
                className="relative cursor-pointer item1-layout"
                onMouseEnter={() => setImageSrc5(item5Effect)}
                onMouseLeave={() => setImageSrc5(item5)}
                onClick={() => handleClick("ligue1")}
              >
                <Image
                  src={imageSrc5}
                  width="100%"
                  height="100%"
                  className="item1"
                  preview={false}
                />
              </div>
            </div>
            <div className="absolute bottom-0 w-full calendar-bg">
              <div className="bg-[url('./assets/footer.webp')] bg-cover bg-center h-full calendar"></div>
            </div>
          </div>
        )}
        <Popup
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          title={title}
          schedule={schedule}
          isMobile={isMobile}
        />
      </div>
    </>
  );
}

export default Home;
